import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { Button, Container, Row, Spinner } from 'reactstrap'
import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import dayjs from 'dayjs'
import { GDWPurchaseForm } from '../../components/GDWPurchaseForm'
import { GDWTable } from '../../components/GDWTable'

const initAWHClass = {}

export default function PurchaseHistory() {
  const [selectedItem, setSelectedItem] = useState(null)

  useFirestoreConnect([
    {
      collection: '_purchases',
      orderBy: ['purchaseDate', 'desc'],
      storeAs: 'purchases',
    },
  ])

  const purchases = useSelector((state) => state.firestore.ordered.purchases)

  console.log('purchases = ', purchases)

  const data = React.useMemo(() => purchases || [])
  const columns = React.useMemo(
    () => [
      {
        id: 'purchaseDate',
        Header: 'Transaction Date',
        accessor: (d) =>
          dayjs(d.purchaseDate?.seconds * 1000).format('YYYY-MM-DD hh:mm:ss'),
      },
      {
        Header: 'Purchaser',
        accessor: 'uid',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        id: 'more',
        Header: 'More',
        accessor: (d) => (
          <Button
            onClick={() => {
              console.log('d = ', d)
              setSelectedItem(d)
            }}>
            more
          </Button>
        ),
      },
    ],
    [],
  )

  return (
    <div className="content">
      <Container>
        <div className="pt-3">
          <div className="h5">Purchases History</div>
        </div>
        <GDWTable
          isLoading={!isLoaded(purchases)}
          data={data}
          columns={columns}
        />
        <Button className="my-3" color="primary">
          Load More
        </Button>
        <SlidingPane
          overlayClassName="sliding-pane-zindex"
          isOpen={Boolean(selectedItem)}
          onRequestClose={() => setSelectedItem(null)}>
          <GDWPurchaseForm purchase={selectedItem} />
        </SlidingPane>
      </Container>
    </div>
  )
}
