import * as React from 'react'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'

export interface IGDWProviderFormProps {
  provider: object | null
}

export function GDWProviderForm(props: IGDWProviderFormProps) {
  const { provider } = props
  console.log('provider = ', provider)
  return (
    <div>
      <div className="h3">Provider</div>
      <JSONPretty id="json-pretty" data={provider}></JSONPretty>
    </div>
  )
}
