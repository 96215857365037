import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { Button, Container, Row, Spinner } from 'reactstrap'
import GDWAWHClass from '../../components/GDWAWHClass'
import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import { GDWClassForm } from '../../components/GDWClassForm'

const initAWHClass = {}

export default function AWHClasses() {
  const [selectedItem, setSelectedItem] = useState(null)

  useFirestoreConnect([
    {
      collection: 'awhClasses',
      storeAs: 'awhClasses',
    },
    {
      collection: 'coaches',
      storeAs: 'coaches',
    },
  ])

  const awhClasses = useSelector((state) => state.firestore.ordered.awhClasses)

  console.log('awhClasses = ', awhClasses)

  return (
    <div className="content">
      <Container>
        <div className="pt-3">
          <div className="h5">AWH Classes</div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            className="my-3"
            onClick={() => setSelectedItem(initAWHClass)}>
            Add
          </Button>
        </div>
        {isLoaded(awhClasses) ? (
          awhClasses.map((classData, idx) => (
            <GDWAWHClass
              key={idx}
              classData={classData}
              onClick={() => setSelectedItem(classData)}
            />
          ))
        ) : (
          <Spinner />
        )}
        <SlidingPane
          overlayClassName="sliding-pane-zindex"
          isOpen={Boolean(selectedItem)}
          onRequestClose={() => setSelectedItem(null)}>
          <GDWClassForm classData={selectedItem} />
        </SlidingPane>
      </Container>
    </div>
  )
}
