import * as React from 'react'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'

export interface IGDWHospitalFormProps {
  hospital: object | null
}

export function GDWHospitalForm(props: IGDWHospitalFormProps) {
  const { hospital } = props
  console.log('hospital = ', hospital)
  return (
    <div>
      <div className="h3">Hospital</div>
      <JSONPretty id="json-pretty" data={hospital}></JSONPretty>
    </div>
  )
}
