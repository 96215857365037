import * as React from 'react'
import { useState } from 'react'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'
import { Button, Col, Input, Row } from 'reactstrap'
import { daysOfweek } from '../utils/date'
import { IAWHClass } from '../types'

import _ from 'lodash'
import GDWButton from './GDWButton'
import { useFirebaseConnect, useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

interface IGDWClassFormProps {
  classData: IAWHClass
}
export function GDWClassForm(props: IGDWClassFormProps) {
  const [classData, setClassData] = useState<IAWHClass>(props.classData)
  const [saving, setSaving] = useState(false)
  const coaches = useSelector((state) => state.firestore.ordered.coaches)
  console.log('coaches = ', coaches)
  const firestore = useFirestore()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    transform?: Function,
  ) => {
    const v = event.target.value
    console.log('v = ', v)
    const value = transform ? transform(v) : v
    console.log('key, value = ', key, value)
    setClassData({ ...classData, [key]: value })
  }
  const handleSave = async () => {
    try {
      setSaving(true)
      if (classData.id) {
        await firestore.update(`awhClasses/${classData.id}`, {
          ...classData,
          updatedAt: firestore.FieldValue.serverTimestamp(),
        })
      } else {
        // create new awhSession
        await firestore.add('awhClasses', {
          ...classData,
          createdAt: firestore.FieldValue.serverTimestamp(),
          updateAt: firestore.FieldValue.serverTimestamp(),
        })
      }
      alert('saved!')
    } catch (e) {
      console.error('failed to save class: ', e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <div>
      <div className="h3">Class</div>
      <div className="m-3">
        <Row>
          <div>Coach</div>
        </Row>
        <Row>
          <Input
            type="select"
            value={classData.coach}
            onChange={(e) => handleChange(e, 'coach')}>
            {coaches.map((coach, index) => (
              <option key={coach.uid} value={coach.uid}>
                {`${coach.displayName} (${coach.uid})`}
              </option>
            ))}
          </Input>
        </Row>
      </div>
      <div className="m-3">
        <Row>
          <div>Title</div>
        </Row>
        <Row>
          <Input
            value={classData.title}
            onChange={(e) => handleChange(e, 'title')}
          />
        </Row>
      </div>

      <div className="m-3">
        <Row>
          <div>Days of Week</div>
        </Row>
        <Row className="px-3">
          {daysOfweek.map((day, idx) => (
            <Col key={idx}>
              <Input
                type="checkbox"
                value={idx}
                defaultChecked={classData?.daysOfWeek?.includes(idx)}
                onChange={(e) =>
                  handleChange(e, 'daysOfWeek', (v: any) =>
                    _(classData.daysOfWeek)
                      .xor([Number(v)])
                      .sort()
                      .value(),
                  )
                }
              />{' '}
              {day}
            </Col>
          ))}
        </Row>
      </div>

      <div className="m-3">
        <Row>
          <div>Time setting</div>
        </Row>
        <Row>
          <Col>
            <div>Start</div>
            <Input
              type="time"
              value={classData.startTime}
              onChange={(e) => handleChange(e, 'startTime')}
            />
          </Col>
          <Col>
            End
            <Input
              type="time"
              value={classData.endTime}
              onChange={(e) => handleChange(e, 'endTime')}
            />
          </Col>
        </Row>
      </div>

      <div className="m-3">
        <Row>
          <div>Status</div>
        </Row>
        <Row>
          <Col>
            <Input
              id="status"
              name="status"
              type="select"
              onChange={(e) => handleChange(e, 'status')}>
              {['active', 'inactive', 'unlist'].map((item, idx) => (
                <option key={idx} selected={classData.status === item}>
                  {item}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
      </div>
      <div className="m-3">
        <Row>
          <div>Languages</div>
        </Row>
        <Row>
          <Col>
            <Input
              id="lang"
              name="lang"
              type="select"
              multiple
              onChange={(e) => handleChange(e, 'lang')}>
              {['en', 'ko'].map((item, idx) => (
                <option key={idx} selected={classData.lang?.includes(item)}>
                  {item}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
      </div>
      <div className="m-3">
        <GDWButton isLoading={saving} onClick={handleSave} block>
          Save
        </GDWButton>
      </div>
      <JSONPretty id="json-pretty" data={props}></JSONPretty>
    </div>
  )
}
