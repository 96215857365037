import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { Button, Container, Row, Spinner } from 'reactstrap'
import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import GDWAWHSession from '../../components/GDWAWHSession'
import { GDWSessionForm } from '../../components/GDWSessionForm'

const initAWHSession = {}

export default function AWHSessions() {
  const [selectedItem, setSelectedItem] = useState(null)

  useFirestoreConnect([
    {
      collection: 'awhSessions',
      storeAs: 'awhSessions',
    },
  ])

  const awhSessions = useSelector(
    (state) => state.firestore.ordered.awhSessions,
  )

  console.log('awhClasses = ', awhSessions)

  return (
    <div className="content">
      <Container>
        <div className="pt-3">
          <div className="h5">AWH Sessions</div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            className="my-3"
            onClick={() => setSelectedItem(initAWHSession)}>
            Add
          </Button>
        </div>
        {isLoaded(awhSessions) ? (
          awhSessions.map((sessionData, idx) => (
            <GDWAWHSession
              key={idx}
              data={sessionData}
              onClick={() => setSelectedItem(sessionData)}
            />
          ))
        ) : (
          <Spinner />
        )}
        <SlidingPane
          overlayClassName="sliding-pane-zindex"
          isOpen={Boolean(selectedItem)}
          onRequestClose={() => setSelectedItem(null)}>
          <GDWSessionForm
            data={selectedItem}
            close={() => setSelectedItem(null)}
          />
        </SlidingPane>
      </Container>
    </div>
  )
}
