import * as React from 'react'
import { usePagination, useRowSelect, useTable } from 'react-table'
import { Spinner } from 'reactstrap'

import { GDTableStyles } from './GDTableStyles'

interface IPaging {
  currentPage: number
  pageSize: number
  pages: Array<string>
  total: number
  gotoPage: Function
}

export interface IGDWTableProps {
  isLoading?: boolean
  columns: []
  data: []
  paging?: IPaging
}

export function GDWTable(props: IGDWTableProps) {
  const { isLoading, columns, data, paging } = props
  const [isOpen, setIsOpen] = React.useState(false)
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const renderPaging = () => {
    const { currentPage, total, pageSize, pages } = props.paging
    const pageCount = pages.length

    const canPreviousPage = currentPage > 0
    const canNextPage = currentPage < pageCount

    const gotoPage = (page: number) => {
      props.paging.gotoPage(page)
    }
    const previousPage = () => gotoPage(currentPage - 1)
    const nextPage = () => gotoPage(currentPage + 1)

    return (
      <div className="pagination d-flex justify-content-end">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {currentPage + 1} of {pageCount}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={currentPage + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
      </div>
    )
  }

  return (
    <GDTableStyles>
      {isLoading ? (
        <Spinner color="primary" />
      ) : (
        <>
          <table {...getTableProps}>
            <thead>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => (
                        // Apply the header cell props
                        <th {...column.getHeaderProps()}>
                          {
                            // Render the header
                            column.render('Header')
                          }
                        </th>
                      ))
                    }
                  </tr>
                ))
              }
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                rows.map((row) => {
                  // Prepare the row for display
                  prepareRow(row)
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render('Cell')
                              }
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <div className="pt-3">{paging && renderPaging()}</div>
        </>
      )}
    </GDTableStyles>
  )
}
