import React from 'react'

import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'

import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'

import {
  Container,
  Media,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from 'reactstrap'
import { GDWTable } from '../../components/GDWTable'
import { GDWProviderForm } from '../../components/GDWProviderForm'
import { UserLevel } from '../../types'

export default function Providers() {
  useFirestoreConnect([
    {
      collection: 'users',
      where: ['level', '==', UserLevel.PROVIDER],
      storeAs: 'providers',
    },
  ])

  const [selectedUser, setSelectedUser] = React.useState(null)

  const providers = useSelector((state) => state.firestore.ordered.providers)
  console.log('providers = ', providers)

  const data = React.useMemo(() => providers || [])
  const columns = React.useMemo(
    () => [
      {
        id: 'profile',
        Header: 'Profile',
        accessor: (d) =>
          d.photoURL && (
            <Media
              object
              src={d.photoURL}
              alt={`${d.nickname}'s photo`}
              style={{ width: 60, height: 60, borderRadius: 30 }}
            />
          ),
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Nickname',
        accessor: 'nickname',
      },
      {
        Header: 'email',
        accessor: 'email',
      },
      {
        Header: 'Singup Reason',
        accessor: 'signupReason',
      },
      {
        id: 'more',
        Header: 'More',
        accessor: (d) => (
          <Button
            onClick={() => {
              console.log('d = ', d)
              setSelectedUser(d)
            }}>
            more
          </Button>
        ),
      },
    ],
    [],
  )
  return (
    <div className="content">
      <Container className="mt-3">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/">Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem>Users</BreadcrumbItem>
          <BreadcrumbItem active>Provider List</BreadcrumbItem>
        </Breadcrumb>
        <div className="h3">Provider List</div>
        <div className="pb-5">
          <GDWTable
            isLoading={!isLoaded(providers)}
            data={data}
            columns={columns}
          />
        </div>
        <SlidingPane
          overlayClassName="sliding-pane-zindex"
          isOpen={Boolean(selectedUser)}
          onRequestClose={() => setSelectedUser(null)}>
          <GDWProviderForm provider={selectedUser} />
        </SlidingPane>
      </Container>
    </div>
  )
}
