import { actionNames } from '../actions/actionNames'

const initialState = {
  message: null,
  type: 'info',
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionNames.SET_ALERT_MESSAGE:
      return { ...state, message: payload.message }
    default:
      return state
  }
}
