import * as React from 'react'
import { useState } from 'react'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'
import { Button, Col, Input, Row } from 'reactstrap'
import { daysOfweek } from '../utils/date'

import _ from 'lodash'
import GDWButton from './GDWButton'
import {
  isLoaded,
  useFirebaseConnect,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase'
import { IAWHSession } from './GDWAWHSession'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../redux/store'
import { setAlertMessage } from '../actions/ui'

interface IGDWSessionFormProps {
  data: IAWHSession
  close: () => {}
}
export function GDWSessionForm(props: IGDWSessionFormProps) {
  const [data, setData] = useState<IAWHSession>(props.data)
  const [saving, setSaving] = useState(false)

  const firestore = useFirestore()
  const dispatch = useDispatch()
  useFirestoreConnect([{ collection: 'awhClasses' }])
  const awhClasses = useSelector(
    (state: IRootState) => state.firestore.ordered.awhClasses,
  )

  React.useEffect(() => {
    if (data.awhClassId) {
      const found = _.find(awhClasses, { id: data.awhClassId })
      if (found) {
        setData({ ...data, coach: found.coach })
      }
    }

    return () => {
      // cleanup
    }
  }, [data.awhClassId])

  React.useEffect(() => {
    console.log('data = ', data)
  }, [data])

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    transform?: Function,
  ) => {
    const v = event.target.value
    console.log('v = ', v)
    const value = transform ? transform(v) : v
    console.log('value = ', value)
    setData({ ...data, [key]: value })
  }
  const handleSave = async () => {
    try {
      setSaving(true)
      if (data.id) {
        await firestore.update(`awhSessions/${data.id}`, {
          ...data,
          updatedAt: firestore.FieldValue.serverTimestamp(),
        })
      } else {
        // create new awhSession
        await firestore.add('awhSessions', {
          ...data,
          createdAt: firestore.FieldValue.serverTimestamp(),
          updateAt: firestore.FieldValue.serverTimestamp(),
        })
      }
      dispatch(setAlertMessage(`${data.title} saved!`))
    } catch (e) {
      console.error('failed to save class: ', e)
    } finally {
      setSaving(false)
      props.close()
    }
  }

  return (
    <div>
      <div className="h3">Session</div>
      <div className="m-3">
        <Row>
          <div>Class</div>
        </Row>
        <Row>
          <Input
            type="select"
            value={data?.awhClassId || ''}
            onChange={(e) => handleChange(e, 'awhClassId')}>
            {isLoaded(awhClasses) &&
              awhClasses.map((classData, index) => (
                <option
                  selected={data.awhClassId === classData.id}
                  key={classData.id}
                  value={classData.id}>
                  {`${classData.title} (${classData.id})`}
                </option>
              ))}
          </Input>
        </Row>
      </div>
      <div className="m-3">
        <Row>
          <div>Title</div>
        </Row>
        <Row>
          <Input
            value={data.title}
            onChange={(e) => handleChange(e, 'title')}
          />
        </Row>
      </div>

      <div className="m-3">
        <Row>
          <div>Days of Week</div>
        </Row>
        <Row className="px-3">
          {daysOfweek.map((day, idx) => (
            <Col key={idx}>
              <Input
                type="checkbox"
                value={idx}
                defaultChecked={data?.daysOfWeek?.includes(idx)}
                onChange={(e) =>
                  handleChange(e, 'daysOfWeek', (v: any) =>
                    _(data.daysOfWeek)
                      .xor([Number(v)])
                      .sort()
                      .value(),
                  )
                }
              />{' '}
              {day}
            </Col>
          ))}
        </Row>
      </div>
      <div className="m-3">
        <Row>
          <Col>
            <div>Start Date</div>
            <Input
              type="date"
              value={data.startDate}
              onChange={(e) => handleChange(e, 'startDate')}
            />
          </Col>
          <Col>
            <div>Start Time</div>
            <Input
              type="time"
              value={data.startTime}
              onChange={(e) => handleChange(e, 'startTime')}
            />
          </Col>
        </Row>
      </div>
      <div className="m-3">
        <Row>
          <div>Status</div>
        </Row>
        <Row className="px-3">
          {['active', 'inactive'].map((item, idx) => (
            <Col key={idx}>
              <Input
                type="checkbox"
                value={item}
                defaultChecked={data?.status === item}
                onChange={(e) => handleChange(e, 'status')}
              />{' '}
              {item}
            </Col>
          ))}
        </Row>
      </div>
      <div className="m-3">
        <GDWButton isLoading={saving} onClick={handleSave} block>
          Save
        </GDWButton>
      </div>
      <JSONPretty id="json-pretty" data={props}></JSONPretty>
    </div>
  )
}
