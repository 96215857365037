import * as React from 'react'
import { useState } from 'react'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'
import { Button, Container, Col, Input, Row } from 'reactstrap'
import { daysOfweek } from '../utils/date'
import { ICoach } from '../types'

import _ from 'lodash'
import GDWButton from './GDWButton'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import GDUtil from '../utils/GDUtil'
import uuid from 'react-uuid'

interface IGDWNewCoachFormProps {
  toggle: () => any
}
export function GDWNewCoachForm(props: IGDWNewCoachFormProps) {
  const [coachData, setCoachData] = useState<ICoach | any>({})
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [saving, setSaving] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const firestore = useFirestore()
  const firebase = useFirebase()

  React.useEffect(() => {
    setButtonDisabled(handleDisabled())
    return () => {
      // cleanup
    }
  }, [coachData, password, passwordConfirm, file, email])

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    transform?: Function,
  ) => {
    const v = event.target.value
    console.log('v = ', v)
    const value = transform ? transform(v) : v
    console.log('value = ', value)
    setCoachData({ ...coachData, [key]: value })
  }

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
    }
  }

  const handleSave = async () => {
    try {
      setSaving(true)
      let photoURL = null
      // create new coaches
      if (file) {
        const { uploadTaskSnapshot } = await firebase.uploadFile(
          `coaches/${uuid()}`,
          file,
        )
        photoURL = await uploadTaskSnapshot.ref.getDownloadURL()
      }
      // @ts-ignore
      const app = firebase.app()
      const createCoachCallable = app
        .functions('asia-northeast3')
        .httpsCallable('createCoach')
      const result = await createCoachCallable({
        ...coachData,
        photoURL,
        email,
        password,
      })
      console.log('result = ', result)
      alert('saved!')
    } catch (e) {
      console.error('failed to save coach: ', e)
    } finally {
      setSaving(false)
      props.toggle()
    }
  }

  const handleDisabled = () => {
    if (!GDUtil.validEmail(email)) {
      console.log('invalid email')
      return true
    }

    if (coachData.displayName?.length === 0) {
      console.log('invalid displayName')
      return true
    }

    if (!file) {
      console.log('invalid file')
      return true
    }

    if (password?.length === 0 || passwordConfirm?.length === 0) {
      console.log('password length')
      return true
    }

    if (password !== passwordConfirm) {
      console.log('invalid passwordConfirm')
      return true
    }
    return false
  }

  return (
    <div>
      <div className="h3">Register a new coach</div>
      <div style={{ height: '500px', overflowY: 'scroll' }}>
        <Row className="m-1">
          <div>Step1. Register account</div>
        </Row>
        <Container className="my-3">
          <Row>
            <Col>
              Email
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </Col>
          </Row>
          <Row>
            <Col>
              Password
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Col>
            <Col>
              Password confirm
              <Input
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Col>
          </Row>
        </Container>
        <Row className="m-1">
          <div>Step2. Fill the form</div>
        </Row>
        <Container className="my-3">
          <Row>
            <Col>
              <div>Display name</div>
              <Input
                value={coachData.displayName}
                onChange={(e) => handleChange(e, 'displayName')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div>Photo</div>
              <Input
                type="file"
                multiple={false}
                onChange={handleFile}
                accept="image/*"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div>Bio</div>
              <Input
                type="textarea"
                value={coachData.bio}
                onChange={(e) => handleChange(e, 'bio')}
              />
            </Col>
          </Row>
          <Row className="m-1 mt-3">
            <GDWButton
              disabled={buttonDisabled}
              isLoading={saving}
              onClick={handleSave}
              block>
              Save
            </GDWButton>
          </Row>
        </Container>
      </div>
    </div>
  )
}
