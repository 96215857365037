export enum UserLevel {
  CLIENT = 1,
  PROVIDER = 2,
}

export interface IPublicUser {
  displayName: string
  photoURL?: string
  uid: string
}

export interface IMember extends IPublicUser {
  memberSince: Date
}

export interface ICoach extends IPublicUser {
  bio: string
}

export interface IApplication extends IPublicUser {
  submitDate: Date
}

export enum DASCategory {
  D = 'D', // depress
  A = 'A', // axioty
  S = 'S', // stress
}

export interface IClient {
  id?: string
  basicInfo?: { [key: string]: string }
  deviceTokens?: string[]
  email: string
  level: UserLevel
  nickname: string
  passAppData?: { [key: string]: string }
  phoneNo?: string
  photoURL?: string
  awhSessionId?: string
  DASS?: {
    answers: { answer: number; category: DASCategory }[]
    dateCompleted: string
  }
  MDQ?: {
    answers: { answer: number; category: any | null }[]
    dateCompleted: string
  }
  signupReason: string
}

export interface IAWHClass {
  id?: string
  title: string
  daysOfWeek: Array<number>
  startTime: string
  endTime: string
  startDate: string
  endDate: string
  coach: ICoach
  members: Array<IPublicUser>
  applications: Array<IApplication>
  lang: string[]
  status: string
}
