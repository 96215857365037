import { actionNames } from '../actions/actionNames'

const initialState = {
  adminUsers: [],
  clients: [],
  coachUsers: [],
  providers: [],
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionNames.GET_ADMIN_USERS:
      return { ...state, adminUsers: payload.adminUsers }
    case actionNames.GET_COACH_USERS:
      return { ...state, coachUsers: payload.coachUsers }

    default:
      return state
  }
}
