import * as React from 'react'
import { useState } from 'react'

import {
  Collapse,
  Container,
  Row,
  Col,
  Modal,
  ModalFooter,
  ModalBody,
  Button,
  Media,
  Spinner,
  Badge,
} from 'reactstrap'
import { daysOfweek } from '../utils/date'

import { BsPencil, BsTrash, BsX } from 'react-icons/bs'
import { useFirestore } from 'react-redux-firebase'
import { IAWHClass } from '../types'
import { useSelector } from 'react-redux'
import { IRootState } from '../redux/store'
import GDWAWHSession, { IAWHSession } from './GDWAWHSession'
import SlidingPane from 'react-sliding-pane'
import { GDWSessionForm } from './GDWSessionForm'

export interface IGDWAWHClassProps {
  classData: IAWHClass
  onClick: Function
}

export default function GDWAWHClass(props: IGDWAWHClassProps) {
  const { classData, onClick } = props
  const {
    id,
    title,
    daysOfWeek,
    startTime,
    endTime,
    coach: coachId,
    members,
    applications,
    status,
    lang,
  } = classData
  const [confirmModal, setConfirmModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [sessions, setSessions] = useState<any[] | IAWHSession[]>([])
  const [selectedSession, setSelectedSession] = useState<IAWHSession | null>(
    null,
  )
  const [loading, setLoading] = useState(false)
  const ref = React.useRef()

  const firestore = useFirestore()
  const coach = useSelector(
    (state: IRootState) =>
      state.firestore.data.coaches && state.firestore.data.coaches[coachId],
  )

  const removeClass = async () => {
    try {
      await firestore.doc(`awhClasses/${id}`).delete()
      alert('Deleted')
    } catch (error) {
      console.error('failed to remove class')
    } finally {
      setConfirmModal(false)
    }
  }

  const loadSessions = async () => {
    try {
      setLoading(true)
      const colsnap = await firestore
        .collection('awhSessions')
        .where('awhClassId', '==', id)
        .orderBy('title')
        .get()
      setSessions(colsnap.docs.map((doc) => doc.data()))
    } catch (error) {
      console.error('Failed to load sessions: ', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container className="border border-success rounded-3 p-3 my-2">
      <Row>
        <Col className="m-3">
          <Badge
            className="mx-1"
            color={status === 'active' ? 'success' : 'secondary'}>
            {status}
          </Badge>
          {lang.map((item, idx) => (
            <Badge key={idx} className="mx-1" color="primary">
              {item}
            </Badge>
          ))}
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <BsPencil
            fontSize={20}
            fontWeight={'bold'}
            onClick={onClick}
            className="mx-1"
          />
          <BsTrash
            fontSize={20}
            fontWeight={'bold'}
            onClick={(e) => {
              setConfirmModal(true)
              e.stopPropagation()
            }}
            className="mx-1"
          />
        </Col>
      </Row>
      <Row className="px-3 d-flex">
        <Col className="col-3 p-2 mx-2 border" style={{ minHeight: 100 }}>
          <Row className="justify-content-center">
            <Media
              src={coach?.photoURL}
              style={{ maxWidth: 80, maxHeight: 80 }}
            />
          </Row>
          <Row className="mt-1 justify-content-center">
            <div>{coach?.displayName}</div>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col className="h6">{title}</Col>
          </Row>
          <Row>
            <Col>{daysOfWeek.map((day) => daysOfweek[day]).join(',')}</Col>
            <Col>
              {startTime} to {endTime}
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal isOpen={confirmModal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button onClick={() => setConfirmModal(false)}>Cancel</Button>
          <Button color="danger" onClick={removeClass}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Button
          size="sm"
          className="mx-4 mt-3"
          outline
          onClick={() => setIsOpen(!isOpen)}>
          {loading ? <Spinner size="sm" /> : 'Sessions'}
        </Button>
      </Row>
      <Row>
        <Collapse
          className="mx-3 d-flex flex-row flex-wrap"
          isOpen={isOpen}
          onEntering={loadSessions}>
          {sessions.map((session, idx) => (
            <GDWAWHSession
              key={idx}
              data={session}
              onClick={() => setSelectedSession(session)}
            />
          ))}
          {sessions.map((session, idx) => (
            <GDWAWHSession
              key={idx}
              data={session}
              onClick={() => setSelectedSession(session)}
            />
          ))}
        </Collapse>
      </Row>
      <SlidingPane
        overlayClassName="sliding-pane-zindex"
        isOpen={Boolean(selectedSession)}
        onRequestClose={() => setSelectedSession(null)}>
        <GDWSessionForm
          data={selectedSession}
          close={() => setSelectedSession(null)}
        />
      </SlidingPane>
    </Container>
  )
}
