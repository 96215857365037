import React from 'react'

import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'

import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'

import { Container, Breadcrumb, BreadcrumbItem, Button } from 'reactstrap'
import { GDWTable } from '../../components/GDWTable'
import { GDWPartnerForm } from '../../components/GDWPartnerForm'

export default function Partners() {
  useFirestoreConnect([
    {
      collection: 'clinics',
      storeAs: 'partners',
      limit: 20,
    },
  ])

  const [selectedItem, setSelectedItem] = React.useState(null)

  const partners = useSelector((state) => state.firestore.ordered.partners)
  console.log('partners = ', partners)

  const data = React.useMemo(() => partners || [])
  const columns = React.useMemo(
    () => [
      {
        Header: '전문분야',
        accessor: 'areaOfExpertise',
      },
      {
        Header: 'Title',
        accessor: 'hospitalName',
      },
      {
        id: 'address',
        Header: 'Address',
        accessor: 'address',
      },
      {
        Header: 'Phone',
        accessor: 'phoneNo',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'CEO',
        accessor: 'ceoName',
      },
      {
        id: 'more',
        Header: 'More',
        accessor: (d) => (
          <Button
            onClick={() => {
              console.log('d = ', d)
              setSelectedItem(d)
            }}>
            more
          </Button>
        ),
      },
    ],
    [],
  )
  return (
    <div className="content">
      <Container className="mt-3">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/">Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem>Hosptials</BreadcrumbItem>
          <BreadcrumbItem active>Partner List</BreadcrumbItem>
        </Breadcrumb>
        <div className="h3">Partner List</div>
        <GDWTable
          isLoading={!isLoaded(partners)}
          data={data}
          columns={columns}
        />
        <SlidingPane
          overlayClassName="sliding-pane-zindex"
          isOpen={Boolean(selectedItem)}
          onRequestClose={() => setSelectedItem(null)}>
          <GDWPartnerForm partner={selectedItem} />
        </SlidingPane>
      </Container>
    </div>
  )
}
