class GDUtil {
  validInviteCode(code: string) {
    if (typeof code !== 'string') return false
    const re = /^[A-Za-z0-9_-]{4}$/
    return re.test(code)
  }

  validEmail(email: string) {
    if (typeof email != 'string') return false
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (re.test(email)) return true
    return false
  }

  validPassword(password: string) {
    if (typeof password != 'string') return false
    if (
      password.length >= 8 &&
      /^(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/.test(password)
    )
      return true
    return false
  }

  removeNonNumbers(inStr: string) {
    if (typeof inStr != 'string') return inStr
    return inStr.replace(/\D/g, '')
  }

  isNumeric(inStr: string) {
    if (typeof inStr != 'string')
      return !isNaN(inStr) && !isNaN(parseFloat(inStr))
  }

  validMobilePhoneNo(inStr: string) {
    return /^\d{3}-\d{3,4}-\d{4}/.test(inStr)
  }

  transformMobilePhoneToDashedForm(inStr: string) {
    if (this.validMobilePhoneNo(inStr)) return inStr
    const numbers = inStr.replace(/^\+82/, '').replace(/\D/g, '')
    return numbers
      .split('')
      .map((number, idx) => ([3, 7].includes(idx) ? '-' + number : number))
      .join('')
  }

  mobilePhoneNoToE164(inStr: string) {
    if (/^\+?[1-9]\d{1,14}$/.test(inStr)) return inStr
    const numbers = inStr.replace(/\D/g, '')
    return '+82' + (numbers[0] === '0' ? numbers.substr(1) : numbers)
  }
}

export default new GDUtil()
