import { actionNames } from './actionNames'

export const setAlertMessage =
  (message: string) =>
  async (dispatch: Function, getState: Function, getFirebase: Function) => {
    dispatch({
      type: actionNames.SET_ALERT_MESSAGE,
      payload: { message },
    })
  }
