import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
} from 'reactstrap'
import dayjs from 'dayjs'
import { GDWTable } from '../../components/GDWTable'
import {
  isLoaded,
  useFirebase,
  useFirestoreConnect,
} from 'react-redux-firebase'
import { setAlertMessage } from '../../actions/ui'
import GDWButton from '../../components/GDWButton'
import { ICoach } from '../../types'
import GDWCoach from '../../components/GDWCoach'
import SlidingPane from 'react-sliding-pane'
import { GDWCoachForm } from '../../components/GDWCoachForm'
import { GDWNewCoachForm } from '../../components/GDWNewCoachForm'
import { IRootState } from '../../redux/store'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const initCoach = {}

export default function Coaches() {
  const dispatch = useDispatch()
  const firebase = useFirebase()

  const [coachUsers, setCoachUsers] = useState([])
  const [selectedItem, setSelectedItem] = useState<ICoach | any>(null)
  const [modal, setModal] = useState(false)
  const [email, setEmail] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [newCoachModalVisible, setNewCoachModalVisible] = useState(false)

  const toggle = () => setModal(!modal)
  const toggleNewCoach = () => setNewCoachModalVisible(!newCoachModalVisible)
  const grant = async () => {
    console.log('grant....')
    try {
      setIsSaving(true)
      const app = firebase.app()
      const setCoachCallable = app
        .functions('asia-northeast3')
        .httpsCallable('setCoach')
      const { data } = await setCoachCallable({ email, enable: true })
      console.log('re = ', data)
      if (data === true) {
        dispatch(setAlertMessage('Granted!'))
      } else {
        dispatch(setAlertMessage('Something wrong! ' + data))
      }
      toggle()
    } catch (error) {
      console.error('failed to grantAdmin', error)
    } finally {
      setIsSaving(false)
    }
  }
  // const getCoachUsers = async () => {
  //   try {
  //     setIsLoading(true)
  //     //@ts-ignore
  //     const app = firebase.app()
  //     const getCoachUsersCallable = app
  //       .functions('asia-northeast3')
  //       .httpsCallable('getCoachUsers')

  //     const { data } = await getCoachUsersCallable()
  //     console.log('re = ', data)
  //     setCoachUsers(data)
  //   } catch (error) {
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }
  // useEffect(() => {
  //   getCoachUsers()
  // }, [])

  const data = useMemo(() => coachUsers, [coachUsers])
  const columns = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email', // accessor is the "key" in the data
      },
      {
        id: 'coach',
        Header: 'coach',
        accessor: (d: any) => (d.customClaims.coach ? 'True' : 'False'),
      },
      {
        id: 'lastLogin',
        Header: 'last login',
        accessor: (d: any) => dayjs(d.metadata.lastSignInTime).toNow(),
      },
    ],
    [],
  )

  useFirestoreConnect([
    {
      collection: 'coaches',
    },
  ])

  const coaches = useSelector((state: IRootState) =>
    state.firestore.ordered.coaches?.map((coach) => ({
      ...coach,
      uid: coach.id,
    })),
  )

  return (
    <div className="content">
      <Container className="mt-3">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/">Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem>AWH</BreadcrumbItem>
          <BreadcrumbItem active>Coach List</BreadcrumbItem>
        </Breadcrumb>
        {/* <div className="d-flex justify-content-end">
          <Button color="primary" className="m-3 float-right" onClick={toggle}>
            Add new
          </Button>
        </div>

        <div className="pb-5">
          <GDWTable isLoading={isLoading} columns={columns} data={data} />
        </div>

        <Modal isOpen={modal} toggle={toggle} onClosed={() => setEmail('')}>
          <ModalHeader toggle={toggle}>Grant a new coach privilage</ModalHeader>
          <ModalBody>
            <Form onSubmit={grant}>
              <FormGroup>
                <Label for="email">Email to grant coach</Label>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  value={email}
                  name="email"
                  id="email"
                  placeholder="john@mail.com"
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <GDWButton color="primary" onClick={grant} isLoading={isSaving}>
              Grant
            </GDWButton>{' '}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal> */}
        <Modal
          isOpen={newCoachModalVisible}
          toggle={toggleNewCoach}
          onClosed={() => console.log('closed')}>
          <ModalHeader toggle={toggleNewCoach}>New coach register</ModalHeader>
          <ModalBody>
            <GDWNewCoachForm toggle={toggleNewCoach} />
          </ModalBody>
        </Modal>

        <div className="pt-3">
          <div className="h5">Coaches</div>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            className="my-3"
            onClick={() => toggleNewCoach()}>
            Add
          </Button>
        </div>
        {isLoaded(coaches) ? (
          coaches.map((coachData, idx) => (
            <GDWCoach
              key={idx}
              coachData={coachData}
              onClick={() => setSelectedItem(coachData)}
            />
          ))
        ) : (
          <Spinner />
        )}
        <SlidingPane
          overlayClassName="sliding-pane-zindex"
          isOpen={Boolean(selectedItem)}
          onRequestClose={() => setSelectedItem(null)}>
          <GDWCoachForm coachData={selectedItem} />
        </SlidingPane>
      </Container>
    </div>
  )
}
