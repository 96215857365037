import * as React from 'react'
import { useState } from 'react'

import {
  Container,
  Row,
  Col,
  Modal,
  ModalFooter,
  ModalBody,
  Button,
} from 'reactstrap'
import { daysOfweek } from '../utils/date'

import { BsTrash, BsX } from 'react-icons/bs'
import { remove } from 'lodash'
import { useFirestore } from 'react-redux-firebase'
import { IApplication, ICoach, IPublicUser } from '../types'

export interface IAWHSession {
  id?: string
  awhClassId: string
  title: string
  daysOfWeek: number[]
  startTime: string
  startDate: string
  members: string[]
  applications: string[]
  status: string
}

export interface IGDWAWHSessionProps {
  data: IAWHSession
  onClick: () => any
}

export default function GDWAWHSession(props: IGDWAWHSessionProps) {
  const { data, onClick } = props
  const { id, title, daysOfWeek, startTime, endTime, members, applications } =
    data
  const [confirmModal, setConfirmModal] = useState(false)

  const firestore = useFirestore()

  const removeSession = async () => {
    try {
      await firestore.doc(`awhSessions/${id}`).delete()
      alert('Deleted')
    } catch (error) {
      console.error('failed to remove session')
    } finally {
      setConfirmModal(false)
    }
  }
  return (
    <Container
      className="border border-gray rounded-lg p-3 my-2"
      onClick={onClick}>
      <Row className="d-flex">
        <Col className="d-flex justify-content-end ">
          <BsTrash
            fontSize={20}
            fontWeight={'bold'}
            onClick={(e) => {
              setConfirmModal(true)
              e.stopPropagation()
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="h6">{title}</Col>
      </Row>
      <Row>
        <Col>{daysOfWeek.map((day) => daysOfweek[day]).join(',')}</Col>
        <Col>
          {startTime} to {endTime}
        </Col>
      </Row>
      <Row>
        <Col>members: {members?.length || 0}</Col>
        <Col>applicants: {applications?.length || 0}</Col>
      </Row>
      <Modal isOpen={confirmModal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button onClick={() => setConfirmModal(false)}>Cancel</Button>
          <Button color="danger" onClick={removeSession}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  )
}
