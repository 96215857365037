import * as React from 'react'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'

export interface IGDWPurchaseFormProps {
  client: object | null
}

export function GDWPurchaseForm(props: IGDWPurchaseFormProps) {
  const { purchase } = props
  console.log('purchase = ', purchase)
  return (
    <div>
      <div className="h3">Purchase</div>
      <JSONPretty id="json-pretty" data={purchase}></JSONPretty>
    </div>
  )
}
