import * as React from 'react'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'

export interface IGDWPartnerFormProps {
  partner: object | null
}

export function GDWPartnerForm(props: IGDWPartnerFormProps) {
  const { partner } = props
  console.log('partner = ', partner)
  return (
    <div>
      <div className="h3">Partner</div>
      <JSONPretty id="json-pretty" data={partner}></JSONPretty>
    </div>
  )
}
