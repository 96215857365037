/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap'
import GDUtil from 'utils/GDUtil'

function Login() {
  React.useEffect(() => {
    document.body.classList.toggle('login-page')
    return function cleanup() {
      document.body.classList.toggle('login-page')
    }
  })

  const [user, setUser] = React.useState({ email: '', password: '' })
  const [error, setError] = React.useState(null)
  const firebase = useFirebase()
  const hist = useHistory()
  const doLogin = async () => {
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(user.email, user.password)
      hist.push('/')
    } catch (error) {
      setError(error.message)
    }
  }
  React.useEffect(() => {
    setError(null)
    return () => {
      // cleanup
    }
  }, [user])

  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form action="" className="form" method="">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">Login</h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="text"
                      name="email"
                      id="email"
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      name="password"
                      id="password"
                      autoComplete="off"
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                    />
                  </InputGroup>
                  <br />
                  {error ? (
                    <p className="text-center text-warning">{error}</p>
                  ) : null}
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className="btn-round mb-3"
                    color="warning"
                    href="#pablo"
                    disabled={
                      !(
                        GDUtil.validEmail(user.email) &&
                        GDUtil.validPassword(user.password)
                      )
                    }
                    onClick={doLogin}>
                    Login
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${
            require('assets/img/bg/fabio-mangione.jpg').default
          })`,
        }}
      />
    </div>
  )
}

export default Login
