import dayjs from 'dayjs'
import * as React from 'react'
import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase'
import { Container, Row, Col, Button, Form, Input } from 'reactstrap'
import GDWButton from '../../components/GDWButton'
import { GDWTable } from '../../components/GDWTable'
export interface INotificationsProps {}

export default function Notifications(props: INotificationsProps) {
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [target, setTarget] = useState('all')
  const firestore = useFirestore()

  useFirestoreConnect({
    collection: '_notifications',
    orderBy: ['createdAt', 'desc'],
    limit: 20,
    storeAs: 'notifications',
  })

  const notifications = useSelector(
    (state) => state.firestore.ordered.notifications,
  )

  console.log('notifications = ', notifications)

  const saveNotification = () => {
    console.log('saveNotification!!')
    try {
      setLoading(true)
      firestore.collection('_notifications').add({
        target,
        message,
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp(),
      })
    } catch (error) {
      console.error('Failed to get notifications', error)
    } finally {
      setMessage('')
      setLoading(false)
    }
  }

  const data = notifications
  const columns = useMemo(
    () => [
      {
        Header: 'Target',
        accessor: 'target', // accessor is the "key" in the data
      },
      {
        Header: 'Message',
        accessor: 'message',
      },
      {
        id: 'createdAt',
        Header: 'CreatedAt',
        accessor: (d: any) =>
          dayjs(d.createdAt?.seconds * 1000).format('YYYY-MM-DD hh:mm:ss'),
      },
    ],
    [],
  )
  return (
    <div className="content">
      <Container>
        <div>Notifications</div>

        <Row className="p-3">
          <Col xs="3">
            <Input
              type="select"
              name="target"
              id="targetSelect"
              onChange={(e) => setTarget(e.target.value)}>
              <option value="all">All</option>
              <option value="providers">Providers</option>
              <option value="clients">Clients</option>
              <option value="coaches">Coaches</option>
            </Input>
          </Col>
          <Col xs="auto">
            <Input
              type="textarea"
              onChange={(e) => setMessage(e.target.value)}
            />
          </Col>
          <Col xs="3">
            <GDWButton
              isLoading={loading}
              disabled={message.length < 10}
              onClick={saveNotification}>
              Send
            </GDWButton>
          </Col>
        </Row>
        <Row className="p-3">
          {isLoaded(notifications) && (
            <GDWTable data={data} columns={columns} />
          )}
        </Row>
      </Container>
    </div>
  )
}
