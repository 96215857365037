import React, { useState, useEffect } from 'react'

import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'

import { useSelector } from 'react-redux'
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase'

import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Row,
  Col,
} from 'reactstrap'
import { GDWTable } from '../../components/GDWTable'
import { GDWHospitalForm } from '../../components/GDWHospitalForm'
import { usePagingFirestoreConnect } from '../../hooks/usePagingFirestoreConnect'
import GDWButton from '../../components/GDWButton'

export default function Hospitals() {
  const [loading, setLoading] = useState(false)
  const [hospitals, setHospitals] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const firestore = useFirestore()
  useFirestoreConnect([
    {
      collection: '_pages',
      doc: 'hospitals_title1_20',
      storeAs: 'paging',
    },
  ])

  const paging = useSelector((state) => state.firestore.data.paging)
  console.log('paging = ', paging)

  useEffect(() => {
    gotoPage(1)
    return () => {
      // cleanup
    }
  }, [paging])

  const [selectedItem, setSelectedItem] = React.useState(null)

  const gotoPage = async (newPage) => {
    console.log('newPage = ', newPage)
    if (newPage === undefined) return null
    if (!paging) return null

    const { pages, pageSize } = paging
    if (!pages || !pageSize) return

    if (newPage < 0 || newPage > pages.length - 1) return 'out-of-pages'

    try {
      setLoading(true)
      let ref = firestore
        .collection('hospitals')
        .orderBy('title1', 'asc')
        .limit(pageSize)
      if (newPage > 0) {
        ref = ref.startAfter(pages[newPage - 1])
      }
      console.log('ref = ', ref)
      const colsnap = await ref.get()
      setCurrentPage(newPage)
      setHospitals(colsnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    } catch (error) {
      console.error('Fail to get hospitals: ', error)
    } finally {
      setLoading(false)
    }
  }

  const data = React.useMemo(() => hospitals || [])
  const columns = React.useMemo(
    () => [
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        id: 'title',
        Header: 'Title',
        accessor: (d) => `${d.title1 || ''} ${d.title2 || ''}`.trim(),
      },
      {
        id: 'address',
        Header: 'Address',
        accessor: (d) => `${d.address1 || ''} ${d.address2 || ''}`.trim(),
      },
      {
        Header: 'Phone',
        accessor: 'phoneNo',
      },
      {
        id: 'website',
        Header: 'Website',
        accessor: (d) => (
          <a href={d.website} alt={d.website}>
            {d.website}
          </a>
        ),
      },
      {
        id: 'more',
        Header: 'More',
        accessor: (d) => (
          <Button
            onClick={() => {
              console.log('d = ', d)
              setSelectedItem(d)
            }}>
            more
          </Button>
        ),
      },
    ],
    [],
  )

  return (
    <div className="content">
      <Container className="mt-3">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/">Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem>Hosptials</BreadcrumbItem>
          <BreadcrumbItem active>Hospital List</BreadcrumbItem>
        </Breadcrumb>
        <div className="h3">Hosptial List</div>
        {isLoaded(paging) && (
          <div className="pb-5">
            <GDWTable
              // isLoading={loading}
              data={data}
              columns={columns}
              paging={{ ...paging, gotoPage: gotoPage, currentPage }}
            />
            {/* <Row className="mt-3">
            <Col>
              <GDWButton
                isLoading={loading}
                onClick={() => gotoPage(currentPage + 1)}>
                Next
              </GDWButton>
            </Col>
          </Row> */}
          </div>
        )}

        <SlidingPane
          overlayClassName="sliding-pane-zindex"
          isOpen={Boolean(selectedItem)}
          onRequestClose={() => setSelectedItem(null)}>
          <GDWHospitalForm hospital={selectedItem} />
        </SlidingPane>
      </Container>
    </div>
  )
}
