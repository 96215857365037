import * as React from 'react'
import { useState } from 'react'

import {
  Container,
  Row,
  Col,
  Modal,
  ModalFooter,
  ModalBody,
  Button,
  Input,
  Media,
} from 'reactstrap'
import { daysOfweek } from '../utils/date'

import { BsX } from 'react-icons/bs'
import { useFirestore } from 'react-redux-firebase'
import { ICoach } from '../types'

export interface IGDWCoachProps {
  coachData: ICoach
  onClick: () => any
}

export default function GDWCoach(props: IGDWCoachProps) {
  const { coachData, onClick } = props
  const { uid, displayName, photoURL, bio } = coachData
  const [confirmModal, setConfirmModal] = useState(false)

  const firestore = useFirestore()

  const removeCoach = async () => {
    try {
      await firestore.doc(`coaches/${uid}`).delete()
      alert('Deleted')
    } catch (error) {
      console.error('failed to remove coach')
    } finally {
      setConfirmModal(false)
    }
  }
  return (
    <Container
      className="border border-success rounded-lg p-3 my-2"
      onClick={onClick}>
      <Row>
        <Col className="text-right">
          <BsX
            fontSize={20}
            fontWeight={'bold'}
            onClick={(e) => {
              setConfirmModal(true)
              e.stopPropagation()
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="m-3">
          <Row>
            <Media
              object
              src={photoURL}
              alt={`${displayName}'s photo'`}
              style={{ maxWidth: 100, maxHeight: 60 }}
            />
          </Row>
          <Row>{displayName}</Row>
        </Col>
        <Col className="h6 m-3"></Col>
      </Row>
      <Row>
        <Col>{bio}</Col>
      </Row>
      <Modal isOpen={confirmModal}>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <Button onClick={() => setConfirmModal(false)}>Cancel</Button>
          <Button color="danger" onClick={removeCoach}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  )
}
