/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

import icons from "variables/icons";

function Icons() {
  return (
    <div className="content">
      <Row>
        <Col md={12}>
          <Card className="demo-icons">
            <CardHeader>
              <CardTitle>100 Awesome Nucleo Icons</CardTitle>
              <p className="card-category">
                Handcrafted by our friends from{" "}
                <a href="https://nucleoapp.com/?ref=1712">NucleoApp</a>
              </p>
            </CardHeader>
            <CardBody className="all-icons">
              <div id="icons-wrapper">
                <section>
                  <ul>
                    {icons.map((prop, key) => {
                      return (
                        <li key={key}>
                          <i className={prop.name} />
                          <p>{prop.name}</p>
                          <em>{prop.content}</em>
                        </li>
                      );
                    })}
                  </ul>
                </section>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Icons;
