import React, { useState } from 'react'

import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'

import { useSelector } from 'react-redux'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'

import {
  Container,
  Media,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from 'reactstrap'
import { GDWTable } from '../../components/GDWTable'
import { GDWClientForm } from '../../components/GDWClientForm'
import { IClient, UserLevel } from '../../types'
import { IRootState } from '../../redux/store'

const initClient: IClient = {
  email: '',
  level: 1,
  nickname: '',
  signupReason: '',
}

export default function Clients() {
  useFirestoreConnect([
    {
      collection: 'users',
      where: ['level', '==', UserLevel.CLIENT],
      storeAs: 'clients',
    },
  ])

  const [selectedUser, setSelectedUser] = React.useState<IClient | null>(null)

  const clients = useSelector(
    (state: IRootState) => state.firestore.ordered.clients,
  )
  console.log('clients = ', clients)

  const data = React.useMemo(() => clients || [], [clients])
  const columns = React.useMemo(
    () => [
      {
        id: 'profile',
        Header: 'Profile',
        accessor: (d: IClient) =>
          d.photoURL && (
            <Media
              object
              src={d.photoURL}
              alt={`${d.nickname}'s photo`}
              style={{ width: 60, height: 60, borderRadius: 30 }}
            />
          ),
      },
      {
        Header: 'Nickname',
        accessor: 'nickname',
      },
      {
        Header: 'email',
        accessor: 'email',
      },
      {
        Header: 'Singup Reason',
        accessor: 'signupReason',
      },
      {
        id: 'more',
        Header: 'More',
        accessor: (d: IClient) => (
          <Button
            onClick={() => {
              console.log('d = ', d)
              setSelectedUser(d)
            }}>
            more
          </Button>
        ),
      },
    ],
    [],
  )
  return (
    <div className="content">
      <Container className="mt-3">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/">Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem>Users</BreadcrumbItem>
          <BreadcrumbItem active>Client List</BreadcrumbItem>
        </Breadcrumb>
        <div className="h3">Client List</div>
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            className="my-3"
            onClick={() => setSelectedUser(initClient)}>
            Add
          </Button>
        </div>
        <div className="pb-5">
          <GDWTable
            isLoading={!isLoaded(clients)}
            data={data}
            columns={columns}
          />
        </div>

        <SlidingPane
          overlayClassName="sliding-pane-zindex"
          isOpen={Boolean(selectedUser)}
          onRequestClose={() => setSelectedUser(null)}>
          <GDWClientForm client={selectedUser} />
        </SlidingPane>
      </Container>
    </div>
  )
}
