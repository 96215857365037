/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import dayjs from 'dayjs'
import React from 'react'
import { useSelector } from 'react-redux'
import { isLoaded, useFirebase } from 'react-redux-firebase'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Badge,
} from 'reactstrap'

function UserProfile() {
  const auth = useSelector((state) => state.firebase.auth)
  const [claims, setClaims] = React.useState(null)
  const firebase = useFirebase()
  React.useEffect(() => {
    const checkIdToken = async () => {
      const idTokenResult = await firebase
        .auth()
        ?.currentUser?.getIdTokenResult()
      console.log('idTokenResult = ', idTokenResult?.claims)
      setClaims(idTokenResult?.claims)
    }
    checkIdToken()
    return () => {
      // cleanup
    }
  }, [firebase])
  return (
    isLoaded(auth) && (
      <>
        <div className="content">
          <Row>
            <Col md="4">
              <Card className="card-user">
                <div className="image">
                  <img
                    alt="..."
                    src={require('assets/img/bg/damir-bosnjak.jpg').default}
                  />
                </div>
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={auth?.photoURL}
                      />
                      <h5 className="title">{auth?.displayName}</h5>
                    </a>
                  </div>
                  <p className="description text-center">{auth?.email}</p>
                  <p className="text-center">
                    last login at{' '}
                    {dayjs
                      .unix(auth?.lastLoginAt / 1000)
                      .format('YYYY-MM-DD HH:mm:ss')}
                  </p>
                  <p className="text-center">
                    created at{' '}
                    {dayjs
                      .unix(auth?.createdAt / 1000)
                      .format('YYYY-MM-DD HH:mm:ss')}
                  </p>
                  <p className="text-center">
                    {claims?.admin ? (
                      <Badge color="primary" pill>
                        admin
                      </Badge>
                    ) : null}
                    {claims?.coach ? (
                      <Badge color="success" pill>
                        coach
                      </Badge>
                    ) : null}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    )
  )
}

export default UserProfile
