import * as React from 'react'
import { useState } from 'react'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'
import { Button, Col, Input, Row } from 'reactstrap'
import { daysOfweek } from '../utils/date'
import { ICoach } from '../types'

import _ from 'lodash'
import GDWButton from './GDWButton'
import { useFirestore } from 'react-redux-firebase'

interface IGDWCoachFormProps {
  coachData: ICoach
}
export function GDWCoachForm(props: IGDWCoachFormProps) {
  const [coachData, setCoachData] = useState<ICoach>(props.coachData)
  const [saving, setSaving] = useState(false)

  const firestore = useFirestore()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    transform?: Function,
  ) => {
    const v = event.target.value
    console.log('v = ', v)
    const value = transform ? transform(v) : v
    console.log('value = ', value)
    setCoachData({ ...coachData, [key]: value })
  }
  const handleSave = async () => {
    try {
      setSaving(true)
      if (coachData.uid) {
        await firestore.update(`coaches/${coachData.uid}`, {
          ...coachData,
          updatedAt: firestore.FieldValue.serverTimestamp(),
        })
      } else {
        // create new awhSession
        await firestore.add('coaches', {
          ...coachData,
          createdAt: firestore.FieldValue.serverTimestamp(),
          updateAt: firestore.FieldValue.serverTimestamp(),
        })
      }
      alert('saved!')
    } catch (e) {
      console.error('failed to save coach: ', e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <div>
      <div className="h3">Coach</div>
      <div className="m-3">
        <Row>
          <div>Display Name</div>
        </Row>
        <Row>
          <Input
            value={coachData.displayName}
            onChange={(e) => handleChange(e, 'displayName')}
          />
        </Row>
      </div>
      <div className="m-3">
        <Row>
          <div>Bio</div>
        </Row>
        <Row>
          <Input
            value={coachData.bio}
            onChange={(e) => handleChange(e, 'bio')}
          />
        </Row>
      </div>

      <div className="m-3">
        <GDWButton isLoading={saving} onClick={handleSave} block>
          Save
        </GDWButton>
      </div>
      <JSONPretty id="json-pretty" data={props}></JSONPretty>
    </div>
  )
}
