import React from 'react'
import { Spinner, Button, ButtonProps, SpinnerProps } from 'reactstrap'

interface IGDWButton extends ButtonProps {
  isLoading: boolean
  spinnerProps?: SpinnerProps
}

export default function GDWButton({
  isLoading = false,
  disabled = false,
  children,
  ...rest
}: IGDWButton) {
  return (
    <Button disabled={disabled || isLoading} {...rest}>
      {children} {isLoading ? <Spinner size="sm" /> : null}
    </Button>
  )
}
