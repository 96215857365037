import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { getAdminUsers } from '../../actions/users'
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  BreadcrumbItem,
  Breadcrumb,
} from 'reactstrap'
import dayjs from 'dayjs'
import GDWButton from '../../components/GDWButton'
import { useFirebase } from 'react-redux-firebase'
import { setAlertMessage } from '../../actions/ui'
import { GDWTable } from '../../components/GDWTable'

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default function Admins() {
  const dispatch = useDispatch()
  const firebase = useFirebase()

  const [modal, setModal] = useState(false)
  const [email, setEmail] = useState('')
  const [adminUsers, setAdminUsers] = useState([])

  const toggle = () => setModal(!modal)

  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const grant = async () => {
    console.log('grant....')
    try {
      setIsSaving(true)
      const app = firebase.app()
      const setAdminCallable = app
        .functions('asia-northeast3')
        .httpsCallable('setAdmin')
      const { data } = await setAdminCallable({ email, enable: true })
      console.log('re = ', data)
      if (data === true) {
        dispatch(setAlertMessage('Granted!'))
      } else {
        dispatch(setAlertMessage('Something wrong! ' + data))
      }
      toggle()
    } catch (error) {
      console.error('failed to grantAdmin', error)
    } finally {
      setIsSaving(false)
    }
  }

  const getAdmin = async () => {
    try {
      setIsLoading(true)
      const app = firebase.app()
      const getAdminUsersCallable = app
        .functions('asia-northeast3')
        .httpsCallable('getAdminUsers')

      const { data } = await getAdminUsersCallable()
      console.log('data = ', data)
      setAdminUsers(data)
    } catch (error) {
      console.error('failed to get admin users: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAdmin()
  }, [])

  const data = useMemo(() => adminUsers, [adminUsers])
  const columns = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email', // accessor is the "key" in the data
      },
      {
        id: 'admin',
        Header: 'admin',
        accessor: (d) => (d.customClaims.admin ? 'True' : 'False'),
      },
      {
        id: 'lastLogin',
        Header: 'last login',
        accessor: (d) => dayjs(d.metadata.lastSignInTime).toNow(),
      },
    ],
    [],
  )

  return (
    <div className="content">
      <Container className="mt-3">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/">Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem>Users</BreadcrumbItem>
          <BreadcrumbItem active>Admin List</BreadcrumbItem>
        </Breadcrumb>
        <Button color="primary" className="m-3 float-right" onClick={toggle}>
          Grant Admin Privilege
        </Button>
        <div className="pb-5">
          <GDWTable isLoading={isLoading} columns={columns} data={data} />
        </div>
        <Modal isOpen={modal} toggle={toggle} onClosed={() => setEmail('')}>
          <ModalHeader toggle={toggle}>Grant a new admin privilage</ModalHeader>
          <ModalBody>
            <Form onSubmit={grant}>
              <FormGroup>
                <Label for="email">Email to grant admin</Label>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  value={email}
                  name="email"
                  id="email"
                  placeholder="john@mail.com"
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <GDWButton color="primary" onClick={grant} isLoading={isSaving}>
              Grant
            </GDWButton>{' '}
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  )
}
