import React from 'react'
import { useEffect } from 'react'
import logo from './logo.svg'
import './App.css'

import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'
import { useDispatch, useSelector } from 'react-redux'

import AuthLayout from 'layouts/Auth.js'
import AdminLayout from 'layouts/Admin.js'

function App() {
  const firebase = useFirebase()
  const hist = useHistory()
  const location = useLocation()

  //@ts-ignore
  const ui = useSelector((state) => state.ui)

  const dispatch = useDispatch()

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      console.log('***** user = ', user)
      if (!user) {
        hist.replace('/auth/login', null)
        return
      }
      const idTokenResult = await user.getIdTokenResult()
      if (!idTokenResult.claims?.admin) {
        alert('WARNING!!! You have not enough privilege access this website')
        firebase.auth().signOut()
        hist.replace('/auth/login', null)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])
  return (
    <Switch>
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Redirect to="/admin/dashboard" />
    </Switch>
  )
}

export default App
