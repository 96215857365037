import * as React from 'react'
import JSONPretty from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'
import { IClient } from '../types'
import { useState } from 'react'
import { useFirestore } from 'react-redux-firebase'
import { useDispatch } from 'react-redux'
import { setAlertMessage } from '../actions/ui'
import { Row, Col, Input } from 'reactstrap'
import GDWButton from './GDWButton'

export interface IGDWClientFormProps {
  client: IClient
}

export function GDWClientForm(props: IGDWClientFormProps) {
  const { client } = props
  const [data, setData] = useState<IClient>(client)
  const [saving, setSaving] = useState(false)

  const firestore = useFirestore()
  const dispatch = useDispatch()

  console.log('client = ', data)

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    transform?: Function,
  ) => {
    const v = event.target.value
    console.log('v = ', v)
    const value = transform ? transform(v) : v
    console.log('key, value = ', key, value)
    setData({ ...data, [key]: value })
  }

  const handleSave = async () => {
    try {
      setSaving(true)
      if (data.id) {
        // update firestore
        await firestore.update(`users/${data.id}`, {
          ...data,
          updatedAt: firestore.FieldValue.serverTimestamp(),
        })
        dispatch(setAlertMessage('The client has been updated.'))
      } else {
        // create new document
        await firestore.add('users', {
          ...data,
          createdAt: firestore.FieldValue.serverTimestamp(),
          updatedAt: firestore.FieldValue.serverTimestamp(),
        })
        dispatch(setAlertMessage('New client created.'))
      }
    } catch (error) {
      console.log('failed to save client: ', error)
      dispatch(setAlertMessage('Failed to save client'))
    } finally {
      setSaving(false)
    }
  }

  return (
    <div>
      <div className="h3">Client</div>
      <div className="m-3">
        <Row>
          <div>id</div>
        </Row>
        <Row>
          <Input value={data.id} onChange={(e) => handleChange(e, 'id')} />
        </Row>
      </div>
      <div className="m-3">
        <Row>
          <div>nickname</div>
        </Row>
        <Row>
          <Input
            value={data.nickname}
            onChange={(e) => handleChange(e, 'nickname')}
          />
        </Row>
      </div>
      <div className="m-3">
        <Row>
          <div>email</div>
        </Row>
        <Row>
          <Input
            value={data.email}
            onChange={(e) => handleChange(e, 'email')}
          />
        </Row>
      </div>
      <div className="m-3">
        <Row>
          <div>signup reason</div>
        </Row>
        <Row>
          <Input
            value={data.signupReason}
            onChange={(e) => handleChange(e, 'signupReason')}
          />
        </Row>
      </div>

      <div className="m-3">
        <GDWButton isLoading={saving} onClick={handleSave} block>
          Save
        </GDWButton>
      </div>
      <JSONPretty id="json-pretty" data={client}></JSONPretty>
    </div>
  )
}
